<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">
              {{ labels.new }}
            </v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn
            color="error"
            v-bind="bind.actions"
            v-on="on.delete(item)"
            :disabled="item.eliminado"
          >
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Modal from "@/components/Modal/ModalProduct/Modal-DocumentAnexo";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "DocAnexoCrudPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Documento", value: "documento", align: "left" },
      { text: "Descripción", value: "documento_descripcion", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    documentos: [],
  }),

  created() {
    this.setup();
  },

  computed: {
    items() {
      return this.documentos;
    },
  },

  methods: {
    ...mapActions("NewProduct", ["getDocAnexo", "deleteDocumentoAnexo"]),

    setup() {
      this.loading = true;
      this.getDocAnexo()
        .then((response) => {
          console.log(response);
          this.documentos = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },

    deleteItem(item) {
      this.confirm({
        title: "Eliminar documento?",
        description: "Esta a punto de eliminar este documento",
      }).then((answer) => {
        if (answer) {
          this.loading = true;
          this.deleteDocumentoAnexo(item._id)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(error.info);
            });
        }
      });
    },
  },
};
</script>
