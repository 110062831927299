<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <!-- CAMPO CODIGO -->
          <v-text-field
            v-model="form.documento"
            name="DocAnexCod"
            @keyup="codigoDocAnex"
            label="Codigo"
            placeholder="Escriba nombre del Codigo"
            :rules="[(v) => !!v || 'El campo código es requerido.']"
          ></v-text-field>
          <!-- CAMPO DESCRIPCION -->
          <v-text-field
            v-model="form.documento_descripcion"
            name="Documento"
            label="Documento anexo"
            placeholder="Escriba nombre del documento"
            id="documento"
            :rules="[
              (v) => !!v || 'El campo nombre del documento es requerido.',
            ]"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="formLoading">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "DocAnexoFormModal",
  mixins: [FormMixin],
  data() {
    return {
      formLoading: false,
      defaultTitle: "Formulario Documento Anexo",
      form: {
        documento: "",
        documento_descripcion: "",
      },
    };
  },
  methods: {
    ...mapActions("NewProduct", ["CrearDocumentAnexo", "UpdateDocumentoAnexo"]),

    submit() {
      if (this.formComponent) {
        if (this.formComponent.validate()) {
          this.formLoading = true;
          let METHOD = this.CrearDocumentAnexo;
          let formData = {};
          if (this.formData) {
            METHOD = this.UpdateDocumentoAnexo;
            formData.data = { ...this.form };
            formData.id = this.formData._id;
          } else {
            formData = { ...this.form };
          }
          METHOD(formData)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.$emit("submit", this.form);
            })
            .catch((error) => {
              this.snackbar(error.info);
            })
            .finally(() => {
              this.formLoading = false;
            });
        }
      }
    },

    codigoDocAnex() {
      this.form.documento = this.form.documento
        .toUpperCase()
        .replace(/ /g, "_");
    },
  },
};
</script>
